



































































































































































import { Component, Vue } from 'vue-property-decorator'
import VsFormGroup from '@/components/VsFormGroup/Index.vue'
import VsFormSection from '@/components/VsFormSection/Index.vue'
import VsContainer from '@/components/VsContainer/Index.vue'
import {
    getNotifications,
    updateNotifications,
} from '@/api/consoleApi/notifications'
import { UserModule } from '@/store/modules/user'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import VsFixedBottomActions from '@/components/VsFixedBottomActions/Index.vue'

@Component({
    name: 'NotificationsSettings',
    components: {
        VsFormGroup,
        VsFormSection,
        VsContainer,
        VsFixedBottomActions,
    },
})
export default class extends Vue {
    private loading = false
    private pageLoading = false
    private creditEmailReached = {
        active: false,
        to: [],
        type: 'creditEmailReached',
        value: 0,
    }

    private creditSmsReached = {
        active: false,
        to: [],
        type: 'creditSmsReached',
        value: 0,
    }

    $refs: any

    get user () {
        return UserModule.user
    }

    beforeMount () {
        this.getNotifications()
    }

    private async getNotifications () {
        try {
            this.pageLoading = true
            const resp = await getNotifications()
            this.creditEmailReached = resp.data.data.find((el: any) => el.type === 'creditEmailReached') || this.getDefaultSetting('creditEmailReached')
            this.creditSmsReached = resp.data.data.find((el: any) => el.type === 'creditSmsReached') || this.getDefaultSetting('creditSmsReached')
        } catch (e) {
            console.log(e)
        }
        this.pageLoading = false
    }

    private async updateNotifications () {
        const valid = await this.$refs.notificationForm.validate()
        if (!valid) return
        try {
            this.loading = true
            await updateNotifications([this.creditEmailReached, this.creditSmsReached])
            this.$root.$vsToast({
                heading: this.$t('settings.notification.notificationSuccess'),
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
        } catch (e) {
            this.$root.$vsToast({
                heading: this.$t('settings.notification.notificationError'),
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            console.log(e)
        }
        this.loading = false
    }

    private getDefaultSetting (settingName: string) {
        return {
            active: false,
            to: [this.user.email],
            type: settingName,
            value: 0,
        }
    }
}
