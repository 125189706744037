var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VsContainer',{attrs:{"variant":"full-width","noPadding":"","loading":_vm.pageLoading}},[_c('ValidationObserver',{ref:"notificationForm"},[_c('VsFormSection',{attrs:{"label":_vm.$t('settings.notification.sectionTitle')}},[_c('VsFormGroup',{attrs:{"tooltip":_vm.$t('settings.notification.sectionTooltip'),"label":_vm.$t('settings.notification.sectionDescription')}},[_c('VsCheckbox',{staticClass:"vs-mb-4",attrs:{"text":_vm.$t('settings.notification.creditEmailLimitText'),"caption":_vm.$t('settings.notification.creditEmailLimitCaption'),"labelHidden":"","size":"large"},model:{value:(_vm.creditEmailReached.active),callback:function ($$v) {_vm.$set(_vm.creditEmailReached, "active", $$v)},expression:"creditEmailReached.active"}}),(_vm.creditEmailReached.active)?_c('div',{staticClass:"vs-ml-10",staticStyle:{"max-width":"350px"}},[_c('div',[_c('ValidationProvider',{attrs:{"vid":"creditEmailReachedValue","name":_vm.$t('settings.notification.limitName'),"rules":"required|integer|min_value:0","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-mb-4",attrs:{"size":"small","error":errors.length > 0,"errorMessage":errors[0],"variant":"number","min":0,"label":"Soglia"},model:{value:(_vm.creditEmailReached.value),callback:function ($$v) {_vm.$set(_vm.creditEmailReached, "value", _vm._n($$v))},expression:"creditEmailReached.value"}})]}}],null,false,619064905)})],1),_c('div',[_c('VsLabel',{staticClass:"vs-mb-1",attrs:{"size":"small"}},[_vm._v(" Indirizzo email ")]),_vm._l((_vm.creditEmailReached.to),function(email,index){return _c('div',{key:index,staticClass:"vs-flex vs-mb-4 vs-gap-2"},[_c('ValidationProvider',{attrs:{"vid":("creditEmailReachedemail" + index),"name":_vm.$t('settings.notification.emailName'),"rules":"required|email","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-flex-auto",attrs:{"size":"small","error":errors.length > 0,"errorMessage":errors[0],"labelHidden":""},model:{value:(_vm.creditEmailReached.to[index]),callback:function ($$v) {_vm.$set(_vm.creditEmailReached.to, index, _vm._n($$v))},expression:"creditEmailReached.to[index]"}})]}}],null,true)}),(_vm.creditEmailReached.to.length > 1)?_c('VsButton',{staticClass:"vs-flex-none",attrs:{"size":"small","aspect":"ghost","variant":"icon","iconName":"trash"},on:{"click":function($event){return _vm.creditEmailReached.to.splice(index, 1)}}}):_vm._e()],1)})],2)]):_vm._e(),_c('VsCheckbox',{staticClass:"vs-mb-4",attrs:{"text":_vm.$t('settings.notification.creditSmsLimitText'),"caption":_vm.$t('settings.notification.creditSmsLimitCaption'),"labelHidden":"","size":"large"},model:{value:(_vm.creditSmsReached.active),callback:function ($$v) {_vm.$set(_vm.creditSmsReached, "active", $$v)},expression:"creditSmsReached.active"}}),(_vm.creditSmsReached.active)?_c('div',{staticClass:"vs-ml-10",staticStyle:{"max-width":"350px"}},[_c('div',[_c('ValidationProvider',{attrs:{"vid":"creditSmsReachedValue","name":_vm.$t('settings.notification.limitName'),"rules":"required|integer|min_value:0","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-mb-4",attrs:{"size":"small","variant":"number","label":"Soglia","min":0,"error":errors.length > 0,"errorMessage":errors[0],"name":_vm.$t('settings.notification.limitLabel')},model:{value:(_vm.creditSmsReached.value),callback:function ($$v) {_vm.$set(_vm.creditSmsReached, "value", _vm._n($$v))},expression:"creditSmsReached.value"}})]}}],null,false,848060307)})],1),_c('div',[_c('VsLabel',{staticClass:"vs-mb-1",attrs:{"size":"small"}},[_vm._v("Indirizzo email")]),_vm._l((_vm.creditSmsReached.to),function(email,index){return _c('div',{key:index,staticClass:"vs-flex vs-mb-4 vs-gap-2"},[_c('ValidationProvider',{attrs:{"vid":("creditSmsReached" + index),"name":_vm.$t('settings.notification.emailName'),"rules":"required|email","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-flex-auto",attrs:{"size":"small","labelHidden":"","error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.creditSmsReached.to[index]),callback:function ($$v) {_vm.$set(_vm.creditSmsReached.to, index, _vm._n($$v))},expression:"creditSmsReached.to[index]"}})]}}],null,true)}),(_vm.creditSmsReached.to.length > 1)?_c('VsButton',{staticClass:"vs-flex-none",attrs:{"size":"small","aspect":"ghost","variant":"icon","iconName":"trash"},on:{"click":function($event){return _vm.creditSmsReached.to.splice(index, 1)}}}):_vm._e()],1)})],2)]):_vm._e()],1)],1)],1),_c('VsFixedBottomActions',[_c('VsButton',{attrs:{"loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.updateNotifications}},[_vm._v(" Salva ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }