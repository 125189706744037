import { serviceConsoleApi } from '@/utils/request'
import { CancelToken } from 'axios'

export const getNotifications = (params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/user/notifications`,
        {
            params,
            cancelToken,
        },
    )

export const updateNotifications = (data: any[], params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.put(
        `/user/notifications`,
        { data },
        {
            params,
            cancelToken,
        },
    )
